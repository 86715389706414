<template>
  <div class="data-box">
    <van-nav-bar
        title="用餐查询"
        left-text="返回"
        right-text="用餐安排"
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
        @click-right="arrangementDinner"
    />
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <div class="span-key">用餐人员</div>
          <div class="span-input" @click="editMealsInfo">
            <span>{{ mealsInfo.designation ? mealsInfo.designation : "请选择用餐人员" }}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">用餐周期</div>
          <div class="span-value">{{ mealsInfo.mealsCycle ? mealsInfo.mealsCycle : "无" }}</div>
        </div>
      </div>
      <div class="body-div" v-show="mealsList.length > 0">
        <div class="body-div-title align-center">
          用餐安排
        </div>
        <div class="body-div-tables">
          <dl>
            <tr>
              <dt>餐点类别</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{["早餐","午餐","晚餐","宵夜"][item.diningType]}}
              </dd>
            </tr>
            <tr>
              <dt>用餐方式</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{["不安排","派餐","堂食","餐补","客户"][item.arrangeType]}}
              </dd>
            </tr>
            <tr>
              <dt>餐标价格</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{'￥' + item.dealNorm}}
              </dd>
            </tr>
          </dl>
        </div>
        <div v-show="mealsAddressList.length > 0">
          <div class="solid-line"></div>
          <div v-for="(item,index) in mealsAddressList" :key="index">
            <div class="body-div-item">
              <div class="span-key">堂食餐厅</div>
              <div class="span-value">{{ item.objName ? item.objName : "无" }}</div>
            </div>
            <div class="body-div-item">
              <div class="span-key">餐厅地址</div>
              <div class="span-value">{{ item.phoneSite ? item.phoneSite : "无" }}</div>
            </div>
            <div class="dashed-line" v-show="index !== (mealsAddressList.length - 1)"></div>
          </div>
          <div class="solid-line"></div>
        </div>
      </div>
      <div class="body-div" v-show="mealsArrangeList.length > 0">
        <div class="body-div-title align-center">
          用餐记录
        </div>
        <div class="body-div-table">
          <dl>
            <tr>
              <dt>用餐日期</dt>
              <dt>用餐餐点</dt>
              <dt>用餐方式</dt>
              <dt>确认人</dt>
            </tr>
            <tr v-for="item in mealsArrangeList" :key="item.diningType">
              <dd>{{ $tool.dateFormat(new Date(item.createTime)) }}</dd>
              <dd>
                {{["早餐","午餐","晚餐","宵夜"][item.hotelDiningId]}}
              </dd>
              <dd>
                {{item.type === 4 ? '派餐' : '堂食'}}
              </dd>
              <dd>
                {{item.handlePerson}}&nbsp;<a :href="'tel:' + item.handlePhone" class="iconfont icon-dianhua1"></a>
              </dd>
            </tr>
          </dl>
        </div>
      </div>
    </div>
    <!-- <div class="data-box-foot"  v-show="mealsList.length > 0">
      <van-button round block type="info" @click="arrangementDinner">用餐安排</van-button>
    </div> -->
    <van-action-sheet v-model="showMealsPerson" :actions="mealsPersonActions" :description="mealsPersonActions.length > 0 ? '' : '暂无数据'" @select="selectMealsInfo"></van-action-sheet>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  canSelectStayFoodGrantPersons,
  foodObjShow
} from "@/api/index";

export default {
  name: "orgMeals",
  data() {
    return {
      showMealsPerson: false,
      mealsPersonActions: [],
      mealsInfo: {},
      mealsList: [],
      mealsAddressList: [],
      mealsArrangeList: []
    };
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.getActiveObject();
      if(that.$store.getters.getSelectMealsPersonId){
        that.getMealsInfo(that.$store.getters.getSelectMealsPersonId);
      }
    },
    //获取活动对象
    getActiveObject(){
      let that = this;
      let params = {
        actId: that.$store.getters.getActivityId,
        activityIds: [0,1],
        pageNum: 1,
        pageSize: 9999,
        type: 1
      };
      canSelectStayFoodGrantPersons(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.mealsPersonActions = res.data.records;
          that.mealsPersonActions.forEach(item => {
            item.name = item.designation;
          })
        })
      })
    },
    //获取餐食信息
    getMealsInfo(id){
      let that = this;
      let params = {
        ayId : id
      };
      foodObjShow(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.mealsInfo = res.data;
          that.mealsInfo.mealsCycle = that.mealsInfo.actStartDate + " ~ " + that.mealsInfo.actEndDate;
          that.mealsList = that.mealsInfo.activityFoodSets ? that.mealsInfo.activityFoodSets : [];
          that.mealsAddressList = that.mealsInfo.activityRelateObjs ? that.mealsInfo.activityRelateObjs : [];
          that.mealsArrangeList = that.mealsInfo.aoscsList ? that.mealsInfo.aoscsList : [];
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    editMealsInfo(){
      let that = this;
      that.showMealsPerson = true;
    },
    selectMealsInfo(item){
      let that = this;
      that.$store.commit('setSelectMealsPersonId', item.id);
      that.getMealsInfo(item.id);
      that.showMealsPerson = false;
    },
    arrangementDinner(){
      let that = this;
      that.$router.push('orgMeals_query');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>